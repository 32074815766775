@font-face {
    font-family: ApercuPro;
    src: url('./fonts/Apercu-Regular-Pro.ttf') format('truetype');
}

@font-face {
    font-family: ApercuPro;
    src: url('./fonts/Apercu-Bold-Pro.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: ApercuPro;
    src: url('./fonts/Apercu-Black-Pro.ttf') format('truetype');
    font-weight: bolder;
}

@font-face {
    font-family: ApercuPro;
    src: url('./fonts/Apercu-Light-Pro.ttf') format('truetype');
    font-weight: lighter;
}

body {
    background-color: #fff;
    height: 100%;
    margin: 0;
}

#root {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
}

button {
    cursor: pointer;
}
